import Head from 'next/head'

interface Props {
  title?: string
}

function PageHead({ title }: Props) {
  const defaultTitle = 'Notifical'
  const pageTitle = title ? `${title} | ${defaultTitle}` : defaultTitle

  return (
    <Head>
      <title>{pageTitle}</title>
    </Head>
  )
}

export default PageHead
