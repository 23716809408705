import Header from '../header'
import MobileNavigation from '../header/mobile-navigation'
import Footer from '../footer'
import Head from 'next/head'
import { Container } from 'react-bootstrap'
import { Toaster } from 'sonner'

interface Props {
  children: React.ReactNode
  className?: string
}

function Layout({ children, className }: Props) {
  return (
    <>
      <Head>
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#3b5998" />
        <link rel="apple-touch-icon" href="/images/apple-touch-icon.png" />
        <meta name="apple-mobile-web-app-status-bar" content="#3b5998" />
      </Head>
      <>
        <Header />
        <MobileNavigation />
        <Container className={className}>
          <main>{children}</main>
        </Container>
        <Toaster />
      </>
      <Footer />
    </>
  )
}

export default Layout
