import { useTranslation } from 'next-i18next'

function Footer() {
  const { t } = useTranslation('footer')

  return (
    <footer className="text-center py-4">
      {t('copyright')} {new Date().getFullYear()}
    </footer>
  )
}

export default Footer
