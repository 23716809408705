import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useUser } from '../../context/user'
import { useTopics } from '../../context/topics'
import { Role } from '../../types/enums/role.enum'
import LogoWhite from '../../assets/images/logo-white.svg'
import Image from 'next/legacy/image'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Box } from 'react-feather'

function Header() {
  const { t } = useTranslation('header')
  const { user } = useUser()
  const { isSingleTopic, topic } = useTopics()
  const router = useRouter()

  const currentPath = router.pathname

  return (
    <header className="header">
      <Navbar expand="md">
        <Container>
          <Navbar.Brand>
            <Link href={user ? '/topics' : '/'} className="header-logo">
              <Image
                alt="Notifical logo"
                src={LogoWhite}
                width={100}
                height={35}
              />{' '}
            </Link>
          </Navbar.Brand>

          {!user && (
            <>
              <Link href="/register" passHref legacyBehavior>
                <Nav.Link
                  active={currentPath.startsWith(`/register`)}
                  className="text-white fw-bold d-md-none"
                >
                  {t('register')}
                </Nav.Link>
              </Link>
            </>
          )}

          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className={!user ? 'align-items-center d-none d-md-flex' : ''}>
              {user ? (
                <>
                  {isSingleTopic ? (
                    <Link
                      href={`/topics/${topic.slug}`}
                      passHref
                      legacyBehavior
                    >
                      <Nav.Link active={currentPath.startsWith(`/topics`)}>
                        {topic.name}
                      </Nav.Link>
                    </Link>
                  ) : (
                    <Link href="/topics" passHref legacyBehavior>
                      <Nav.Link active={currentPath.startsWith(`/topics`)}>
                        {t('topics')}
                      </Nav.Link>
                    </Link>
                  )}
                  <Link href="/notifications" passHref legacyBehavior>
                    <Nav.Link active={currentPath.startsWith(`/notifications`)}>
                      {t('notifications')}
                    </Nav.Link>
                  </Link>
                  <Link href="/profile" passHref legacyBehavior>
                    <Nav.Link active={currentPath.startsWith(`/profile`)}>
                      {t('profile')}
                    </Nav.Link>
                  </Link>
                  {(user.role === Role.Admin || user.role === Role.Editor) && (
                    <Link href="/admin" passHref legacyBehavior>
                      <Nav.Link
                        className="btn btn-primary text-white ms-md-2 mt-md-0 ms-0 mt-2"
                        active={currentPath.startsWith(`/admin`)}
                      >
                        <Box size={20} /> {t('admin')}
                      </Nav.Link>
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link href="/login" passHref legacyBehavior>
                    <Nav.Link active={currentPath.startsWith(`/login`)}>
                      {t('login')}
                    </Nav.Link>
                  </Link>
                  <Link href="/register" passHref legacyBehavior>
                    <Nav.Link active={currentPath.startsWith(`/register`)}>
                      {t('register')}
                    </Nav.Link>
                  </Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
