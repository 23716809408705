import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useUser } from '../../context/user'
import { useTopics } from '../../context/topics'
import { Role } from '../../types/enums/role.enum'
import { Nav } from 'react-bootstrap'
import { Bell, Box, Home, List, User } from 'react-feather'

function MobileNavigation() {
  const { t } = useTranslation('header')
  const { user } = useUser()
  const { isSingleTopic, topic } = useTopics()
  const router = useRouter()

  const currentPath = router.pathname
  const isPwa =
    (typeof window !== 'undefined' &&
      window.matchMedia('(display-mode: standalone)').matches) ||
    false

  if (!user) {
    return (
      <section
        className={`mobile-navigation mobile-navigation--logged-out d-md-none ${
          isPwa && 'pb-3'
        }`}
      >
        <Nav>
          <Link href="/" passHref legacyBehavior>
            <Nav.Link active={currentPath === '' || currentPath === '/'}>
              <Home size={30} />
              {t('home')}
            </Nav.Link>
          </Link>
          <Link href="/topics" passHref legacyBehavior>
            <Nav.Link active={currentPath.startsWith(`/topics`)}>
              <List size={30} />
              {t('topics')}
            </Nav.Link>
          </Link>
          <Link href="/login" passHref legacyBehavior>
            <Nav.Link active={currentPath.startsWith(`/login`)}>
              <User size={30} />
              {t('login')}
            </Nav.Link>
          </Link>
        </Nav>
      </section>
    )
  }

  return (
    <section className={`mobile-navigation d-md-none ${isPwa && 'pb-3'}`}>
      <Nav>
        {isSingleTopic ? (
          <Link href={`/topics/${topic.slug}`} passHref legacyBehavior>
            <Nav.Link active={currentPath.startsWith(`/topics`)}>
              <List size={30} />
              {topic.name}
            </Nav.Link>
          </Link>
        ) : (
          <Link href="/topics" passHref legacyBehavior>
            <Nav.Link active={currentPath.startsWith(`/topics`)}>
              <List size={30} />
              {t('topics')}
            </Nav.Link>
          </Link>
        )}
        <Link href="/notifications" passHref legacyBehavior>
          <Nav.Link active={currentPath.startsWith(`/notifications`)}>
            <Bell size={30} />
            {t('notifications')}
          </Nav.Link>
        </Link>
        <Link href="/profile" passHref legacyBehavior>
          <Nav.Link active={currentPath.startsWith(`/profile`)}>
            <User size={30} />
            {t('profile')}
          </Nav.Link>
        </Link>
        {(user.role === Role.Admin || user.role === Role.Editor) && (
          <Link href="/admin" passHref legacyBehavior>
            <Nav.Link active={currentPath.startsWith(`/admin`)}>
              <Box size={30} /> {t('admin')}
            </Nav.Link>
          </Link>
        )}
      </Nav>
    </section>
  )
}

export default MobileNavigation
