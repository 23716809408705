import type { NextPage } from 'next'
import Link from 'next/link'
import PageHead from '../src/components/layout/page-head'
import Image from 'next/legacy/image'
import Layout from '../src/components/layout'
import HomeHero from '../src/assets/images/home-hero.png'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { Button } from 'react-bootstrap'

export const getStaticProps = async ({ locale }: any) => ({
  props: {
    ...(await serverSideTranslations(locale, ['header', 'home', 'footer'])),
  },
})

const Home: NextPage = () => {
  const { t } = useTranslation('home')

  return (
    <Layout className="home">
      <PageHead />
      <div className="px-3 p-lg-3 mb-4">
        <div className="py-5 pt-3 home-container">
          <div className="row">
            <div className="col-lg-5 mb-4 pt-3">
              <>
                <h1 className="display-5 fw-bold">
                  {t('title-1')} <br /> {t('title-2')}
                </h1>
                <p className="fs-4">{t('description')}</p>
                <Link href="/topics" legacyBehavior>
                  <Button bsPrefix="btn-cta">{t('find-topics')}</Button>
                </Link>
              </>
            </div>
            <div className="col-lg-7 text-end">
              <Image
                placeholder="blur"
                src={HomeHero}
                alt="Notifical Hero Image"
                width={600}
                height={600}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Home
